export default {
  constructMaterialLookup(version) {
    var m = {}
    for (var i = 0; i < version.materials.length; i++) {
      if (version.materials[i].colourCode) {
        m[version.materials[i].id] = {
          id: version.materials[i].id,
          name: version.materials[i].label + ' - ' + version.materials[i].colourCode,
          uiColour: version.materials[i].displayColour,
          thickness: version.materials[i].thickness,
          sides: version.materials[i]?.sides
        }
      } else {
        m[version.materials[i].id] = {
          id: version.materials[i].id,
          name: version.materials[i].label,
          uiColour: version.materials[i].displayColour,
          thickness: version.materials[i].thickness,
          hasGrain: version.materials[i].hasGrain
        }
      }
    }
    return m
  },
  constructHandleLookup(version, materialLookup) {
    var h = {}
    for (var i = 0; i < version.handles.length; i++) {
      var handleVal = version.handles[i].value
      h[version.handles[i].id] = {
        value: handleVal,
        orientation: version.handles[i].orientation == undefined ? 'h' : version.handles[i].orientation,
        insertMaterial: materialLookup[version.handles[i].insert]
      }
    }
    return h
  },
  constructFrontsArray(version, materialLookup, handleLookup) {
    var a = []
    for (var i = 0; i < version.fronts.length; i++) {
      var f = version.fronts[i]

      a.push({
        uid: f.uid,
        itemNum: f.itemNum,
        type: f.frontType.split('-')[0],
        // DRW type has no cabinet so set to 'b' and look for special at array pos 1 instead of 2
        cabinet: f.frontType.split('-')[0] == 'drw' ? 'b' : f.frontType.split('-')[1],
        special: f.frontType.split('-')[0] == 'drw' ? f.frontType.split('-')[1] : f.frontType.split('-')[2],
        // joiningPos is either ['top', 'mid', 'bot']
        joiningPos: f.joiningPos,
        joiningDouble: f.joiningDouble,
        w: f.w,
        h: f.h,
        qty: f.qty,
        handle: handleLookup[f.handle],
        material: materialLookup[f.material]
      })
    }
    return a
  },
  constructCoverPanelArray(version, materialLookup) {
    let a = []

    if (version.coverPanels) {
      for (const cp of version.coverPanels) {
        // change legacy input into total sum number (rather than text with / separation)
        let lightingNum
        if (cp.lighting) {
          if (typeof cp.lighting === 'string') {
            lightingNum = Number(cp.lighting.split('/').reduce((a, b) => parseInt(a) + parseInt(b), 0))
            cp.lighting = lightingNum
          }
        }

        a.push({
          uid: cp.uid,
          type: cp.coverPanelType,
          material: materialLookup[cp.material],
          lighting: cp.lighting,
          vent: cp.vent,
          ventRef: cp.ventRef,
          ventOffset: cp.ventOffset,
          opposingGrain: cp.opposingGrain,
          lightingRef: cp.lightingRef,
          lightingOffset: cp.lightingOffset,
          w: cp.w,
          h: cp.h,
          itemNum: cp.itemNum,
          qty: cp.qty,
          doubleSided: cp.doubleSided
        })
      }
      return a
    }
  },
  constructUrtilArray(version, materialLookup) {
    let a = []
    if (version.urtils) {
      for (const urtil of version.urtils) {
        // change legacy input into total sum number (rather than text with / separation)
        let lightingNum
        if (urtil.lighting) {
          if (typeof urtil.lighting === 'string') {
            lightingNum = urtil.lighting.split('/').reduce((a, b) => parseInt(a) + parseInt(b), 0)
            urtil.lighting = lightingNum
          }
        }

        a.push({
          uid: urtil.uid,
          name: urtil.name,
          type: urtil.type,
          carcass: materialLookup[urtil.carcass],
          door1: urtil.material1 ? materialLookup[urtil.material1] : null,
          door2: urtil.material2 ? materialLookup[urtil.material2] : null,
          back: urtil.back ? materialLookup[urtil.back] : null,
          lighting: urtil.lighting,
          shelving: urtil.shelving,
          shelvingOptions: urtil.shelvingOptions,
          trimming: urtil.trimming,
          opposingGrain: urtil.opposingGrain,
          preAssembled: urtil.preAssembled,
          w: urtil.w,
          h: urtil.h,
          d: urtil.d,
          compartments: urtil.compartments,
          doorCoverageOne: urtil.doorCoverageOne,
          doorCoverageTwo: urtil.doorCoverageTwo
        })
      }
    }
    return a
  },
  constructPantryArray(version, materialLookup) {
    var a = []
    if (version.pantries) {
      // for (var i = 0; i < version.pantries.length; i++) {
      for (const pantry of version.pantries) {
        // var pantry = version.pantries[i]

        a.push({
          uid: pantry.uid,
          name: pantry.name,
          type: pantry.pantryType,
          hinge: pantry.pantryHinge,
          carcass: materialLookup[pantry.carcass],
          base: pantry.base ? materialLookup[pantry.base] : null,
          back: pantry.back ? materialLookup[pantry.back] : null,
          lighting: pantry.lighting,
          flatPack: pantry.flatPack,
          w: pantry.w,
          h: pantry.h,
          d: pantry.d
        })
      }
    }
    return a
  }
}
