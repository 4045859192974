<template>
  <div>
    <v-overlay v-if="loading" color="primary" :opacity="0.9" class="text-center"
      ><v-progress-circular indeterminate></v-progress-circular>
      <p class="mt-5">Just crunching some numbers...</p>
    </v-overlay>
    <div>
      <div id="view-sub-nav" class="flex justify-space-between">
        <div class="d-flex align-center">
          <v-switch
            color="primary"
            :label="usTally ? 'US Tally' : 'UK Tally'"
            @change="toggleRegion"
            hide-details
            class="my-0 py-0"
          ></v-switch>
          <DateRangePicker :empty="true" class="ml-6" @change="dateRangeChanged"></DateRangePicker>
        </div>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon color="primary" class="mr-1" v-on="on">mdi-help-circle-outline</v-icon>
          </template>
          <span style="white-space: pre-line; opacity: 1;">{{ helpText }}</span>
        </v-tooltip>
      </div>
      <div>
        <v-container fluid>
          <v-row>
            <v-col cols="3">
              <v-card>
                <v-card-title>
                  Scheduled Charged Value
                </v-card-title>
                <v-card-text>
                  {{
                    ukScheduledChargedValue && !usTally
                      ? '£' + $numeral(ukScheduledChargedValue).format('0,0.00')
                      : usScheduledChargedValue && usTally
                      ? '$' + $numeral(usScheduledChargedValue).format('0,0.00')
                      : ' - '
                  }}<small v-if="ukScheduledChargedValueTax || usScheduledChargedValue"> (inc. tax)</small>
                  <br />
                  {{
                    ukScheduledChargedValueTax && !usTally
                      ? '£' + $numeral(ukScheduledChargedValueTax).format('0,0.00')
                      : usScheduledChargedValueTax && usTally
                      ? '$' + $numeral(usScheduledChargedValueTax).format('0,0.00')
                      : ' - '
                  }}<small v-if="ukScheduledChargedValueTax || usScheduledChargedValue"> (tax)</small>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card>
                <v-card-title>
                  Scheduled FOC Value
                </v-card-title>
                <v-card-text>
                  {{
                    ukScheduledFocValue && !usTally
                      ? '£' + $numeral(ukScheduledFocValue).format('0,0.00')
                      : usScheduledFocValue && usTally
                      ? '$' + $numeral(usScheduledFocValue).format('0,0.00')
                      : ' - '
                  }}<small v-if="ukScheduledChargedValueTax || usScheduledChargedValue"> (inc. tax)</small>
                  <br />
                  {{
                    ukScheduledFocValueTax && !usTally
                      ? '£' + $numeral(ukScheduledFocValueTax).format('0,0.00')
                      : usScheduledFocValueTax && usTally
                      ? '$' + $numeral(usScheduledFocValueTax).format('0,0.00')
                      : ' - '
                  }}<small v-if="ukScheduledChargedValueTax || usScheduledChargedValue"> (tax)</small>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card>
                <v-card-title>
                  Unscheduled Charged Value
                </v-card-title>
                <v-card-text>
                  {{
                    ukUnscheduledChargedValue && !usTally
                      ? '£' + $numeral(ukUnscheduledChargedValue).format('0,0.00')
                      : usUnscheduledChargedValue && usTally
                      ? '$' + $numeral(usUnscheduledChargedValue).format('0,0.00')
                      : ' - '
                  }}<small v-if="ukUnscheduledChargedValueTax || usUnscheduledChargedValue"> (inc. tax)</small>
                  <br />
                  {{
                    ukUnscheduledChargedValueTax && !usTally
                      ? '£' + $numeral(ukUnscheduledChargedValueTax).format('0,0.00')
                      : usUnscheduledChargedValueTax && usTally
                      ? '$' + $numeral(usUnscheduledChargedValueTax).format('0,0.00')
                      : ' - '
                  }}<small v-if="ukUnscheduledChargedValueTax || usUnscheduledChargedValue"> (tax)</small>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="3">
              <v-card>
                <v-card-title>
                  Unscheduled FOC Value
                </v-card-title>
                <v-card-text>
                  {{
                    ukUnscheduledFocValue && !usTally
                      ? '£' + $numeral(ukUnscheduledFocValue).format('0,0.00')
                      : usUnscheduledFocValue && usTally
                      ? '$' + $numeral(usUnscheduledFocValue).format('0,0.00')
                      : ' - '
                  }}<small v-if="ukUnscheduledChargedValueTax || usUnscheduledChargedValue"> (inc. tax)</small>
                  <br />
                  {{
                    ukUnscheduledFocValueTax && !usTally
                      ? '£' + $numeral(ukUnscheduledFocValueTax).format('0,0.00')
                      : usUnscheduledFocValueTax && usTally
                      ? '$' + $numeral(usUnscheduledFocValueTax).format('0,0.00')
                      : ' - '
                  }}<small v-if="ukUnscheduledChargedValueTax || usUnscheduledChargedValue"> (tax)</small>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-data-table :items="orders" :headers="headers">
        <template #item.orderNumber="{item}">
          {{ item.id }}
        </template>
        <template #item.orderNumber="{item}">
          {{ item.data().orderNumber || '-' }}
        </template>
        <template #item.invoiceNumber="{item}">
          {{ item.data().foc ? 'FOC' : item.data().xeroInvoiceNumber || ' - ' }}
        </template>
        <template #item.total="{item}">
          {{ usTally ? '$' : '£' }}{{ $numeral(item.data().total).format('0,0.00') }}
        </template>
        <template #item.customerEmail="{item}">
          {{ item.data().customerContactDetails.email }}
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script type="text/javascript">
import DateRangePicker from '@/components/ui/DateRangePicker'

export default {
  name: 'Scheduled',
  components: { DateRangePicker },
  data() {
    return {
      usTally: false,
      activeDateRange: null,
      usUnscheduledChargedValue: null,
      usUnscheduledChargedValueTax: null,
      ukUnscheduledChargedValue: null,
      ukUnscheduledChargedValueTax: null,
      usUnscheduledFocValue: null,
      usUnscheduledFocValueTax: null,
      ukUnscheduledFocValue: null,
      ukUnscheduledFocValueTax: null,

      usScheduledChargedValue: null,
      usScheduledChargedValueTax: null,
      ukScheduledChargedValue: null,
      ukScheduledChargedValueTax: null,
      usScheduledFocValue: null,
      usScheduledFocValueTax: null,
      ukScheduledFocValue: null,
      ukScheduledFocValueTax: null,

      orders: [],
      loading: false,
      headers: [
        {
          text: 'ID',
          sortable: false,
          value: 'id'
        },
        {
          text: 'Order #',
          sortable: false,
          value: 'orderNumber'
        },
        {
          text: 'Invoice #',
          sortable: false,
          value: 'invoiceNumber'
        },
        {
          text: 'Total',
          sortable: false,
          value: 'total'
        },
        {
          text: 'Customer Email',
          sortable: false,
          value: 'customerEmail'
        }
      ],
      helpText:
        'First choose the region, then select the date range.\nFuture dates will mean "NOT dispatched" and past dates will mean "dispatched".\nScheduled values has a task set for dispatch (UK) or production start date (US); unscheduled do not.'
    }
  },
  async created() {
    // Load unscheduled Jobs
    this.loadUnscheduledData()
  },
  methods: {
    toggleRegion() {
      this.usTally = !this.usTally
      this.scheduledValue = null
      this.scheduledFocValue = null
      this.orders = []
      this.loadScheduledData()
      this.loadUnscheduledData()
    },
    dateRangeChanged(range) {
      this.activeDateRange = range
      this.scheduledValue = null
      this.scheduledFocValue = null
      this.orders = []
      this.loadScheduledData()
      this.loadUnscheduledData()
    },
    async loadUnscheduledData() {
      this.loading = true
      let unscheduledSnap
      if (!this.usTally) {
        // UK
        // Load unscheduled Jobs
        unscheduledSnap = await this.$db
          .collection('tasks')
          .where('subtype', '==', 'To customer')
          .where('dateScheduled', '==', null)
          .where('complete', '==', false)
          .get()
      } else {
        // US
        // Load unscheduled Jobs
        unscheduledSnap = await this.$db
          .collection('orders')
          .where('region', '==', 'us')
          .where('dispatchEta', '==', null)
          .where('inProduction', '==', null)
          .get()
      }

      const uniqueOrderIds = []

      for (const unscheduled of unscheduledSnap.docs) {
        if (!uniqueOrderIds.includes(unscheduled.data().orderNumber)) {
          if (unscheduled.data().orderNumber) {
            uniqueOrderIds.push(unscheduled.data().orderNumber)
          }
        }
      }

      let usFocValueIncTax = 0
      let usFocTaxValue = 0
      let ukFocValueIncTax = 0
      let ukFocTaxValue = 0
      let usChargedValueIncTax = 0
      let usChargedTaxValue = 0
      let ukChargedValueIncTax = 0
      let ukChargedTaxValue = 0

      for (const id of uniqueOrderIds) {
        const uniqueOrderSnap = await this.$db
          .collection('orders')
          .where('orderNumber', '==', id)
          .get()
        const value = uniqueOrderSnap.docs[0].data().total

        let salesTax
        if (this.usTally) {
          const quoteSnap = await this.$db
            .collection('quotes')
            .doc(uniqueOrderSnap.docs[0].data().quoteID)
            .get()

          const version = quoteSnap.data().versions.find(version => {
            return version.id === uniqueOrderSnap.docs[0].data().versionID
          })

          if (version?.pricing && version.pricing?.salesTax) {
            salesTax = version.pricing?.salesTax?.amount_to_collect
              ? version.pricing?.salesTax?.amount_to_collect
              : version.pricing?.salesTax?.rate
          } else {
            salesTax = 0
          }
        }

        if (uniqueOrderSnap.docs[0].data().foc) {
          if (this.usTally) {
            usFocValueIncTax += value
            usFocTaxValue += salesTax != undefined ? salesTax : 0
          } else {
            ukFocValueIncTax += value
            ukFocTaxValue += value * 0.2
          }
        } else {
          if (this.usTally) {
            usChargedValueIncTax += value
            usChargedTaxValue += salesTax != undefined ? salesTax : 0
          } else {
            ukChargedValueIncTax += value
            ukChargedTaxValue += value * 0.2
          }
        }
      }

      this.usUnscheduledFocValue = usFocValueIncTax
      this.usUnscheduledFocValueTax = usFocTaxValue

      this.ukUnscheduledFocValue = ukFocValueIncTax
      this.ukUnscheduledFocValueTax = ukFocTaxValue

      this.usUnscheduledChargedValue = usChargedValueIncTax
      this.usUnscheduledChargedValueTax = usChargedTaxValue

      this.ukUnscheduledChargedValue = ukChargedValueIncTax
      this.ukUnscheduledChargedValueTax = ukChargedTaxValue

      this.loading = false
    },

    async loadScheduledData() {
      this.loading = true
      this.orders = []

      if (!this.activeDateRange) {
        this.loading = false
        return
      }

      let ordersSnap
      if (!this.usTally) {
        // UK
        // Load scheduled Jobs
        ordersSnap = await this.$db
          .collection('orders')
          .where('scheduledCompleteDate', '>=', this.activeDateRange[0].startOf('day').toDate())
          .where('scheduledCompleteDate', '<=', this.activeDateRange[1].endOf('day').toDate())
          .get()
      } else {
        // US
        // Load scheduled Jobs
        ordersSnap = await this.$db
          .collection('orders')
          .where('dispatchEta', '>=', this.activeDateRange[0].startOf('day').toDate())
          .where('dispatchEta', '<=', this.activeDateRange[1].endOf('day').toDate())
          .get()
      }

      let usFocValueIncTax = 0
      let usFocTaxValue = 0
      let ukFocValueIncTax = 0
      let ukFocTaxValue = 0
      let usChargedValueIncTax = 0
      let usChargedTaxValue = 0
      let ukChargedValueIncTax = 0
      let ukChargedTaxValue = 0

      for (const order of ordersSnap.docs) {
        const value = order.data().total

        let salesTax
        if (this.usTally) {
          const quoteSnap = await this.$db
            .collection('quotes')
            .doc(order.data().quoteID)
            .get()

          const version = quoteSnap.data().versions.find(version => {
            return version.id === order.data().versionID
          })

          if (version?.pricing && version.pricing?.salesTax) {
            salesTax = version.pricing?.salesTax?.amount_to_collect
              ? version.pricing?.salesTax?.amount_to_collect
              : version.pricing?.salesTax?.rate
          } else {
            salesTax = 0
          }
        }

        if (order.data().foc) {
          if (this.usTally) {
            usFocValueIncTax += value
            usFocTaxValue += salesTax != undefined ? salesTax : 0
          } else {
            ukFocValueIncTax += value
            ukFocTaxValue += value * 0.2
          }
        } else {
          if (this.usTally) {
            usChargedValueIncTax += value
            usChargedTaxValue += salesTax != undefined ? salesTax : 0
          } else {
            ukChargedValueIncTax += value
            ukChargedTaxValue += value * 0.2
          }
        }
      }

      this.orders = ordersSnap.docs

      this.usScheduledFocValue = usFocValueIncTax
      this.usScheduledFocValueTax = usFocTaxValue

      this.ukScheduledFocValue = ukFocValueIncTax
      this.ukScheduledFocValueTax = ukFocTaxValue

      this.usScheduledChargedValue = usChargedValueIncTax
      this.usScheduledChargedValueTax = usChargedTaxValue

      this.ukScheduledChargedValue = ukChargedValueIncTax
      this.ukScheduledChargedValueTax = ukChargedTaxValue

      this.loading = false
    }
  }
}
</script>
