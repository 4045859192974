import makerjs from 'makerjs'

// colors - https://github.com/Microsoft/maker.js/blob/b393c682/packages/maker.js/src/core/exporter.ts#L68
// color defs & code - https://www.w3.org/TR/CSS21/syndata.html#value-def-color
// TODO ensure all layer names confirm to [TOOL] [OPERATION] @ [depth]

const layerNames = {
  // Toolpath Helpers
  boundingBox: {
    label: 'Bounding box',
    colour: makerjs.exporter.colors.lime
  },

  notes: {
    label: 'Notes',
    colour: makerjs.exporter.colors.fuchsia
  },

  warning: {
    label: 'WARNING',
    colour: makerjs.exporter.colors.fuchsia
  },

  template: {
    label: 'Template',
    colour: makerjs.exporter.colors.green
  },

  grouping: {
    label: 'Grouping',
    colour: makerjs.exporter.colors.red
  },

  trim: {
    label: 'Trim',
    colour: makerjs.exporter.colors.red
  },

  // Cutout
  cutout: {
    label: '12mm cutout',
    colour: makerjs.exporter.colors.blue
  },

  // plinthCut: {
  //   label: '12mm plinth cut',
  //   colour: makerjs.exporter.colors.fuchsia
  // },

  // Engrave
  engrave: {
    label: 'Engrave',
    colour: makerjs.exporter.colors.olive
  },

  cpEngrave: {
    label: 'CP Engrave',
    colour: makerjs.exporter.colors.green
  },

  shadowLine: {
    label: 'Shadow Line @ 1.2mm',
    colour: makerjs.exporter.colors.green
  },

  // Hinge
  hingeCup: {
    label: '12mm hinge pocket @ 14mm',
    colour: makerjs.exporter.colors.blue
  },

  hingeDrillSmall: {
    label: '3mm hinge drill @ 14mm',
    colour: makerjs.exporter.colors.orange
  },

  hingeDrillMedium: {
    label: '5mm hinge drill @ 14mm',
    colour: makerjs.exporter.colors.fuchsia
  },

  hingeDrillLarge: {
    label: '8mm hinge drill @ 14mm',
    colour: makerjs.exporter.colors.red
  },

  // Drawer
  drawerHoleSmall: {
    label: '3mm dwr drill @ 14mm',
    colour: makerjs.exporter.colors.orange
  },

  drawerHoleMedium: {
    label: '5mm dwr drill @ 14mm',
    colour: makerjs.exporter.colors.fuchsia
  },

  // EdgePull
  cornerCutout: {
    label: '3mm handle corner @ max',
    colour: makerjs.exporter.colors.orange
  },

  cornerEdgePull: {
    label: '3mm handle corner @ 3.5mm',
    colour: makerjs.exporter.colors.orange
  },

  pocketEdgePull: {
    label: '8mm handle pocket @ 3.5mm',
    colour: makerjs.exporter.colors.teal
  },

  handleDrillEdgePull: {
    label: '3mm handle drill @ 14mm',
    colour: makerjs.exporter.colors.maroon
  },

  // DPull
  dHandleCountersink: {
    label: '5mm countersink @ 3mm',
    colour: makerjs.exporter.colors.teal
  },

  dHandleDrill: {
    label: '5mm handle drill @ max',
    colour: makerjs.exporter.colors.maroon
  },

  // SRG
  cbHandlePocket: {
    label: '8mm pocket @ handle depth',
    colour: makerjs.exporter.colors.olive
  },

  handleMill: {
    label: '12mm handle mill @ max',
    colour: makerjs.exporter.colors.green
  },

  corebox: {
    label: '20mm corebox handle',
    colour: makerjs.exporter.colors.aqua
  },

  //Invisipull
  invisiCorebox: {
    label: '20mm corebox invisi',
    colour: makerjs.exporter.colors.aqua
  },

  // Urtil Body
  urtilSideGroove: {
    label: '8mm side groove @ 5mm',
    colour: makerjs.exporter.colors.gray
  },

  urtilBottomDoorGroove: {
    label: '8mm bottom door groove @ 6mm',
    colour: makerjs.exporter.colors.teal
  },

  urtilTopDoorGroove: {
    label: '8mm top door groove @ 14mm',
    colour: makerjs.exporter.colors.green
  },

  urtilFinPocket: {
    label: '12mm fin pocket @ 13mm',
    colour: makerjs.exporter.colors.olive
  },

  urtilSideRebate: {
    label: '8mm side rebate (inner) @ 5mm',
    colour: makerjs.exporter.colors.olive
  },

  urtilBackPocket: {
    label: '12mm back pocket (inner) @ 13mm',
    colour: makerjs.exporter.colors.olive
  },

  // center line on urtil rebates to be cut "on" line path
  sideRebateLine: {
    label: '8mm side rebate (on) @ 5mm',
    colour: makerjs.exporter.colors.teal
  },

  // center line on urtil backs to be cut "on" line path
  backPocketLine: {
    label: '12mm back pocket (on) @ 13mm',
    colour: makerjs.exporter.colors.teal
  },

  finNotch: {
    label: '5mm fin notch @ max',
    colour: makerjs.exporter.colors.fuchsia
  },

  tSlotGroove: {
    label: 'T-slot groove @ 13mm',
    colour: makerjs.exporter.colors.teal
  },

  // Urtil Cable
  cableCoverGroove: {
    label: '8mm cable cover @ 6mm',
    colour: makerjs.exporter.colors.green
  },

  cableChannelHole: {
    label: '5mm cable channel hole @ 15mm',
    colour: makerjs.exporter.colors.olive
  },

  cableChannelGroove: {
    label: '5mm cable channel groove @ 15mm',
    colour: makerjs.exporter.colors.olive
  },

  millCableCover: {
    label: '12mm mill for 6mm cable cover',
    colour: makerjs.exporter.colors.purple
  },

  millCableCoverLip: {
    label: '12mm mill for 2mm cable cover lip',
    colour: makerjs.exporter.colors.maroon
  },

  cableCoverCutout: {
    label: '5mm cable cover cutout @ max',
    colour: makerjs.exporter.colors.navy
  },

  // Mittled Channel
  mittledGroove: {
    label: '12mm mittled groove @ 15mm',
    colour: makerjs.exporter.colors.fuchsia
  },

  mittledCorner: {
    label: '3mm mittled corner @ 15mm',
    colour: makerjs.exporter.colors.orange
  },

  // Urtil Back & Door
  lightHole: {
    label: '5mm pilot hole @ max',
    colour: makerjs.exporter.colors.purple
  },

  backPilotHole: {
    label: '3mm pilot hole @ 3mm',
    colour: makerjs.exporter.colors.fuchsia
  },

  backScrewHole: {
    label: '3mm back drill @ max',
    colour: makerjs.exporter.colors.blue
  },

  backCountersinkHole: {
    label: '3mm back countersink @ max',
    colour: makerjs.exporter.colors.lime
  },

  backCutout: {
    label: '12mm back cutout @ max',
    colour: makerjs.exporter.colors.navy
  },

  backCableHole: {
    label: '8mm cable hole @ max',
    colour: makerjs.exporter.colors.maroon
  },

  urtilDoorHandle: {
    label: '12mm door handle @ max',
    colour: makerjs.exporter.colors.teal
  },

  urtilDoorCutout: {
    label: '12mm door cutout @ max',
    colour: makerjs.exporter.colors.navy
  },

  // Adjustable Shelf
  shelfDrill: {
    label: '5mm shelf drill @ 10mm',
    colour: makerjs.exporter.colors.purple
  },

  shelfDrillMax: {
    label: '5mm shelf drill @ max',
    colour: makerjs.exporter.colors.maroon
  },

  // Joining Bracket
  joiningBracket: {
    label: '8mm bracket pocket @ 2mm',
    colour: makerjs.exporter.colors.olive
  },

  // Insert Cuts
  insertDrill: {
    label: '3mm insert drill @ max',
    colour: makerjs.exporter.colors.olive
  },

  insertCountersink: {
    label: '3mm insert countersink @ max',
    colour: makerjs.exporter.colors.red
  },

  insertCutout: {
    label: '8mm insert cutout @ max',
    colour: makerjs.exporter.colors.navy
  },

  // Platsa
  platsaKeyhole: {
    label: '8mm keyhole pocket @ 12mm',
    colour: makerjs.exporter.colors.teal
  },

  platsaKeyholeChamfer: {
    label: 'v-bit keyhole chamfer @ 1mm',
    colour: makerjs.exporter.colors.blue
  },

  platsaBaseGroove: {
    label: '5mm base groove @ 10.5mm',
    colour: makerjs.exporter.colors.aqua
  },

  platsaFormOutline: {
    label: 'platsa form outline @ 12mm',
    colour: makerjs.exporter.colors.navy
  },

  // MISC
  spacerPanels: {
    label: 'Spacer Panels',
    colour: makerjs.exporter.colors.olive
  },
  // for 3mm pilot in trim-to-fit urtil and pantry
  maxPilotHole: {
    label: '3mm pilot drill @ max',
    colour: makerjs.exporter.colors.red
  },
  // engraving on back pocket to show trimming edges
  specialEngrave: {
    label: 'Special Engrave @ 13.8mm',
    colour: makerjs.exporter.colors.fuchsia
  },
  ventLine: {
    label: 'Vent Line @ max',
    colour: makerjs.exporter.colors.teal
  }
}

const layerProps = {}
Object.values(layerNames).forEach(value => {
  layerProps[value.label] = { color: value.colour }
})
const layerOptions = { layerOptions: layerProps, fontSize: 10 }

export { layerOptions, layerNames }
