<template>
  <div id="checkout-summary">
    <h2>Order Summary</h2>

    <!-- FRONTS -->
    <v-row
      v-for="(material, key) in requiredMaterialInfo"
      v-if="version.pricing.fronts[material.id].itemCount > 0 || version.pricing.fronts[material.id].total > 0"
      :key="key + 'f'"
    >
      <v-col cols="12" md="8"
        >{{ material.thickness + 'mm '
        }}{{ material.thickness == 4 ? 'Handle Inserts in ' : 'Fronts, cover panels and plinths in '
        }}{{ material.colourCode }} {{ material.label }}</v-col
      >
      <v-col cols="12" md="4" class="text-md-right">{{
        $numeral(version.pricing.fronts[material.id].total).format('$0,0.00')
      }}</v-col>
    </v-row>

    <!-- SPACERS -->
    <v-row v-if="version.spacerPanels && version.spacerPanels.length > 0">
      <v-col cols="12" md="8">Spacer Panels</v-col>
      <v-col cols="12" md="4" class="text-md-right">{{
        $numeral(version.pricing.spacerPanels.totalPrice).format('$0,0.00')
      }}</v-col>
    </v-row>

    <!-- WORKTOPS -->
    <v-row v-if="version.worktops && version.worktops.length > 0">
      <v-col cols="12" md="8">Worktops</v-col>
      <v-col cols="12" md="4" class="text-md-right">{{
        $numeral(version.pricing.worktops.total).format('$0,0.00')
      }}</v-col>
    </v-row>

    <!-- URTILS -->
    <v-row v-if="version.urtils && version.urtils.length > 0">
      <v-col cols="12" md="8">Ürtils</v-col>
      <v-col cols="12" md="4" class="text-md-right">{{
        $numeral(version.pricing.urtils.total).format('$0,0.00')
      }}</v-col>
    </v-row>

    <!-- ADDITIONAL -->
    <div v-if="version.additionalItems && version.additionalItems.length > 0">
      <v-row v-for="(item, key) in version.additionalItems" :key="key + 'a'">
        <v-col cols="12" md="8">{{ item.description }}</v-col>
        <v-col cols="12" md="4" class="text-md-right">{{ $numeral(parseFloat(item.value)).format('$0,0.00') }}</v-col>
      </v-row>
    </div>

    <!-- DELIVERY -->
    <v-row v-if="quote.region !== 'us'">
      <v-col cols="12" md="8">Delivery</v-col>
      <v-col cols="12" md="4" class="text-md-right">{{ $numeral(version.pricing.shipping).format('$0,0.00') }}</v-col>
    </v-row>

    <v-divider class="my-4" />

    <v-row class="first-total">
      <v-col cols="6">Subtotal</v-col>
      <v-col cols="6" class="text-right">{{ $numeral(version.pricing.subtotal).format('$0,0.00') }}</v-col>
    </v-row>
    <v-row v-if="version.pricing.discountedSubtotal && version.pricing.subtotal !== version.pricing.discountedSubtotal">
      <v-col cols="6">Less Discounts</v-col>
      <v-col cols="6" class="text-right">{{ $numeral(version.pricing.discountedSubtotal).format('$0,0.00') }}</v-col>
    </v-row>
    <v-row v-else-if="!version.pricing.discountedSubtotal && version.discount > 0">
      <v-col cols="6">Less {{ version.discount }}% discount</v-col>
      <v-col cols="6" class="text-right">{{
        $numeral(version.pricing.subtotal * (1 - version.discount / 100)).format('$0,0.00')
      }}</v-col>
    </v-row>
    <v-row v-if="quote.region == 'us' && version.pricing.salesTax">
      <v-col xs8>Sales Tax ({{ version.pricing.salesTax.rate * 100 }}%)</v-col>
      <v-col xs4 class="text-right">{{ $numeral(version.pricing.salesTax.amount_to_collect).format('$0,0.00') }}</v-col>
    </v-row>
    <v-row v-else-if="quote.region === 'uk'">
      <v-col cols="6">VAT</v-col>
      <v-col cols="6" class="text-right">{{ $numeral(version.pricing.vatAmount).format('$0,0.00') }}</v-col>
    </v-row>
    <v-row v-if="quote.region == 'us'">
      <v-col cols="12" md="8">Delivery</v-col>
      <v-col cols="12" md="4" class="text-md-right">{{ $numeral(version.pricing.shipping).format('$0,0.00') }}</v-col>
    </v-row>
    <v-divider class="my-4" />
    <v-row v-if="!version.allowDeposit" class="total">
      <v-col cols="6"><b>TOTAL</b></v-col>
      <v-col cols="6" class="text-right">
        <b v-if="version.foc">Free of charge</b>
        <b v-else>{{ $numeral(total).format('$0,0.00') }}</b>
      </v-col>
    </v-row>
    <v-row v-if="version.allowDeposit && !order" class="total">
      <v-col cols="6">TOTAL</v-col>
      <v-col cols="6" class="text-right">{{ $numeral(total).format('$0,0.00') }}</v-col>
      <v-col cols="6"><b>PAYABLE NOW 50%</b></v-col>
      <v-col cols="6" class="text-right"
        ><b>{{ $numeral($utils.toFixedNumber(total / 2, 2)).format('$0,0.00') }}</b></v-col
      >
    </v-row>
    <v-row v-if="version.allowDeposit && order && order.payments.length > 0" class="total">
      <v-col cols="6">TOTAL</v-col>
      <v-col cols="6" class="text-right">{{ $numeral(total).format('$0,0.00') }}</v-col>
      <v-col cols="6"><b>PAYABLE NOW REMAINING</b></v-col>
      <v-col cols="6" class="text-right"
        ><b>{{ $numeral(balanceDue).format('$0,0.00') }}</b></v-col
      >
    </v-row>
    <div v-if="order && order.payments.length > 0 && currentStep != 3">
      <v-divider class="my-4" />
      <v-row v-if="order">
        <v-col cols="6">Payments</v-col>
        <v-col cols="6" class="text-right">
          <div class="pb-4" v-for="(payment, index) in order.payments" :key="index">
            {{ $numeral(payment.amount).format('$0,0.00') }}
          </div>
        </v-col>
      </v-row>
      <v-divider class="mb-4" />
      <v-row>
        <v-col cols="6"><b>Balance Due</b></v-col>
        <v-col cols="6" class="text-right"
          ><b>{{ $numeral(balanceDue).format('$0,0.00') }}</b></v-col
        >
      </v-row>
      <v-divider class="my-4" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Summary',
  props: ['quote', 'version', 'order', 'balanceDue', 'currentStep'],
  computed: {
    requiredMaterialInfo() {
      let materialRequired = this.version.materials.filter(material => {
        if (
          this.version.pricing?.fronts[material.id] &&
          this.version.pricing.fronts[material.id].total > 0 &&
          [24, 25, 36, 18, 4].includes(material.thickness)
        ) {
          return material
        }
      })
      return materialRequired
    },
    total() {
      let t = this.version.pricing.total
      if (this.quote.region === 'us' && this.version.pricing.salesTax)
        t += this.version.pricing.salesTax.amount_to_collect
      return t
    }
  }
}
</script>

<style lang="scss">
#checkout-summary {
  h2 {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: solid 1px grey;
  }
}
</style>
