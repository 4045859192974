<template>
  <div id="samples">
    <div id="view-sub-nav">
      <v-select
        v-if="$store.state.user.permissions.uk"
        v-model="region"
        :items="[
          { text: 'EU', value: 'EU' },
          { text: 'US', value: 'US' }
        ]"
        class="ma-0 pa-0"
        hide-details
      ></v-select>
      <v-switch
        v-model="dispatched"
        :label="dispatched ? 'Dispatched' : 'Awaiting Dispatch'"
        class="dispatch-toggle ma-0 pa-0 ml-3"
        @change="bindSamples"
        hide-details
      ></v-switch>
      <v-spacer></v-spacer>
      <div v-if="selected.length > 0">
        {{ selected.length }} Items Selected
        <v-btn
          class="ml-3"
          depressed
          outlined
          color="primary"
          :disabled="selected.length == 0 || dispatched"
          @click="markAsDispatched"
          >Mark As Dispatched</v-btn
        >
        <v-btn
          class="ml-2"
          depressed
          outlined
          color="primary"
          :disabled="selected.length == 0"
          @click="
            () => {
              region == 'US' ? csvUS() : csv()
            }
          "
          >Download CSV</v-btn
        >
        <v-btn class="ml-3" depressed outlined color="primary" @click="updateMissingCustomers">Update Customers</v-btn>
      </div>
      <v-btn v-else class="ml-3" depressed outlined color="primary" @click="addDialog = true">Add Sample Pack</v-btn>
    </div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="filteredSamples"
      :loading="loading"
      show-select
      item-key="id"
      sortBy="dateCreated"
      sort-desc
      :hide-default-footer="!dispatched"
      :items-per-page="dispatched ? 10 : -1"
    >
      <!-- Headers -->
      <template v-slot:header.date>
        {{ dispatched ? 'Dispatched' : 'Ordered' }}
      </template>

      <template v-slot:item.orderNumber="{ item }">
        {{ item.orderNumber || item.xeroInvoiceNumber || 'FOC' }}
      </template>

      <!-- Items -->
      <template v-slot:item.date="{ item }">
        {{ dispatched ? $utils.convertDate(item.dispatchDate) : $utils.convertDate(item.dateCreated) }}
      </template>
      <template v-slot:item.materials="{ item }">
        {{ item.materials || 'Mixed (OLD)' }}
      </template>
    </v-data-table>

    <v-dialog v-model="addDialog" width="800">
      <component
        :is="region === 'EU' ? 'ukForm' : 'usForm'"
        @cancel="addDialog = false"
        @add="addSamplePack"
      ></component>
    </v-dialog>

    <v-overlay v-if="submitting" class="text-center" opacity=".95" color="primary">
      <v-progress-circular class="mt-4" :size="50" :width="3" color="accent" indeterminate></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import filesaver from 'file-saver'
import ukForm from '@/components/samples/uk-form'
import usForm from '@/components/samples/us-form'

export default {
  name: 'Samples',
  components: { ukForm, usForm },
  data() {
    return {
      dispatched: false,
      addDialog: false,
      region: this.$store.state.user.permissions.uk ? 'EU' : 'US',
      loading: true,
      selected: [],
      samples: [],
      headers: [
        {
          text: 'Date',
          value: 'date'
        },
        {
          text: 'Order #',
          value: 'orderNumber',
          sortable: true
        },
        {
          text: 'Email',
          value: 'customerContactDetails.email',
          sortable: false
        },
        {
          text: 'Materials',
          value: 'materials',
          sortable: false
        },
        {
          text: 'City',
          value: 'customerContactDetails.deliveryCity',
          sortable: false
        },
        {
          text: 'Postcode',
          value: 'customerContactDetails.deliveryPostcode',
          sortable: false
        },
        {
          text: 'Country',
          value: 'customerContactDetails.deliveryCountry',
          sortable: false
        }
      ],
      submitting: false
    }
  },
  created() {
    this.bindSamples()
  },
  watch: {
    region() {
      this.selected = []
    }
  },
  methods: {
    async bindSamples() {
      this.loading = true
      await this.$bind(
        'samples',
        this.$db
          .collection('orders')
          .where('type', '==', 'sample-pack')
          .where('balanceOutstanding', '==', false)
          .where('dispatched', '==', this.dispatched)
      )
      this.loading = false
    },
    toggleAll() {
      if (this.selected.length) this.selected = []
      else this.selected = this.filteredSamples.slice()
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
    constructUkSampleString(sample, material) {
      return `${sample.customerContactDetails.firstName} ${sample.customerContactDetails.lastName};${
        sample.customerContactDetails?.deliveryAddressLine2
          ? sample.customerContactDetails.deliveryAddressLine1 +
            ', ' +
            sample.customerContactDetails.deliveryAddressLine2
          : sample.customerContactDetails.deliveryAddressLine1
      };${sample.customerContactDetails.deliveryCity};${sample.customerContactDetails.deliveryPostcode};${
        sample.customerContactDetails.deliveryCountry
      };${material};\n`
    },
    csv() {
      let s =
        'Shipping Address - Full Name;Shipping address - line 1;Shipping address - City;Shipping address - Postcode;Shipping address - Country;Materials - Product Name;\n'

      for (const sample of this.selected) {
        if (sample.materials === 'both') {
          s += this.constructUkSampleString(sample, 'laminate;')
          s += this.constructUkSampleString(sample, 'veneer;')
        } else {
          s += this.constructUkSampleString(sample, sample.materials || 'mixed;')
        }
      }
      var blob = new Blob([s], { type: 'text/csv' })
      filesaver.saveAs(blob, 'samples.csv')
    },
    csvUS() {
      var s =
        'Order ID (required),Order Date,Order Value,Requested Service,Ship To - Name,Ship To - Company,Ship To - Address 1,Ship To - Address 2,Ship To - Address 3,Ship To - State/Province,Ship To - City,Ship To - Postal Code,Ship To - Country,Ship To - Phone,Ship To - Email,Total Weight in Oz,Dimensions - Length,Dimensions - Width,Dimensions - Height,Notes - From Customer,Notes - Internal,Gift Wrap?,Gift Message\n'
      for (var i = this.selected.length - 1; i >= 0; i--) {
        let c = this.selected[i]

        let address2 = c.customerContactDetails.deliveryAddressLine2
          ? c.customerContactDetails.deliveryAddressLine2
          : ''
        s +=
          c.id +
          ',' +
          this.$moment(c.dateCreated.toDate()).format('M/D/YYYY') +
          ',' +
          5.0 +
          ',' +
          'Standard Shipping' +
          ',' +
          c.customerContactDetails.firstName +
          ' ' +
          c.customerContactDetails.lastName +
          ',,' +
          c.customerContactDetails.deliveryAddressLine1 +
          ',' +
          address2 +
          ',,' +
          c.customerContactDetails.deliveryState +
          ',' +
          c.customerContactDetails.deliveryCity +
          ',' +
          c.customerContactDetails.deliveryPostcode +
          ',' +
          'US' +
          ',,' +
          c.customerContactDetails.email +
          ',' +
          '32' +
          ',0.0,0.0,0.0,,,FALSE,' +
          '\n'
      }
      var blob = new Blob([s], { type: 'text/csv' })
      filesaver.saveAs(blob, 'samples.csv')
    },
    async updateMissingCustomers() {
      this.submitting = true

      try {
        const customerArray = []
        for (const order of this.selected) {
          const c = { ...order.customerContactDetails }
          c.region = order.region
          customerArray.push(c)
        }
        const updateMissingCustomerFunc = this.$firebase.functions().httpsCallable('updateMissingCustomers')
        await updateMissingCustomerFunc(customerArray)
      } catch (error) {
        console.log(error)
        this.submitting = false
        this.selected = []
      }

      this.submitting = false
      this.selected = []
      this.$store.commit('openSnackbar', {
        snackbarText: 'Customers Updated'
      })
    },
    async markAsDispatched() {
      this.submitting = true

      try {
        const orderIdArray = []
        for (const order of this.selected) {
          orderIdArray.push(order.id)
        }
        const invoiceSamplePacksFunc = this.$firebase.functions().httpsCallable('invoiceSamplePacksBulk')
        await invoiceSamplePacksFunc(orderIdArray)
      } catch (error) {
        this.submitting = false
        this.selected = []
      }

      this.submitting = false
      this.$store.commit('openSnackbar', {
        snackbarText: 'Samples dispatched'
      })
    },
    async addSamplePack(form) {
      this.addDialog = false

      let o = {
        customerContactDetails: {
          firstName: form.firstName,
          lastName: form.lastName,
          email: form.email,
          deliveryAddressLine1: form.addressLine1,
          deliveryAddressLine2: form.addressLine2,
          deliveryCity: form.city,
          deliveryPostcode: form.postcode,
          deliveryCountry: form.country,
          billingSameAsDelivery: true
        },
        materials: this.region === 'EU' ? form.materials : 'Mixed (OLD)',
        dateCreated: this.$firebase.firestore.Timestamp.now(),
        type: 'sample-pack',
        region: this.region.toLowerCase(),
        currency: this.region === 'EU' ? 'GBP' : 'USD',
        total: 0,
        balanceOutstanding: false,
        numPayments: 0,
        payments: [],
        dispatched: false
      }

      if (this.region === 'US') {
        o.customerContactDetails.deliveryState = form.state
      }

      await this.$db.collection('orders').add(o)
      this.$store.commit('openSnackbar', { snackbarText: 'Sample Pack Added' })
    }
  },
  computed: {
    filteredSamples() {
      return this.samples.filter(sample => {
        return this.region == 'US'
          ? sample.customerContactDetails.deliveryCountry == 'US'
          : sample.customerContactDetails.deliveryCountry != 'US'
      })
    }
  }
}
</script>
